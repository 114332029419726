<template>
  <div>
    <mail-account-list></mail-account-list>
  </div>
</template>

<script>

export default {
}
</script>


